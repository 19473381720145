import React from 'react'
import {
  IndexRoute,
  Redirect,
  Router,
  Route,
  IndexRedirect,
} from 'react-router'
import History from './History'
import requireRole from './lib/requireRole'
import requireLogin from './lib/requireLogin'
import redirectIfLoggedIn from './lib/redirectIfLoggedIn'
import { deleteTaxId } from './lib/sessionStorage'

import App from './components/App'
import Home from './components/Home'
import Loading from './components/Loading'
import Forbidden from './components/Forbidden'
import ErrorPage from './components/ErrorPage'
import RouteNotFound from './components/RouteNotFound'
import { PatchBreadcrumb } from './components/Common'
import AppProvider from './components/AppProvider'

// Force these dependencies into the main bundle because
// they are used by almost every async bundle.
import 'fixed-data-table-2'
import './components/Common/Fields'

import * as admin from './components/Admin'
import * as alerts from './components/Alerts'
import * as audits from './components/Audits'
import * as auth from './components/Auth'
import * as efforts from './components/DataCollections/Efforts'
import * as outreaches from './components/DataCollections/Outreaches'
import * as products from './components/DataCollections/Products'
import * as productDetails from './components/DataCollections/ProductDetail'
import * as qaReports from './components/DataCollections/QAReports'
import * as fileImport from './components/DataCollections/FileImport'
import * as networkMappings from './components/DataProcessing/NetworkMappings'
import * as projectMappings from './components/DataProcessing/ProjectMappings'
import * as serviceAreas from './components/DataProcessing/ServiceAreas'
import * as sources from './components/Sources'
import * as mrfs from './components/MachineReadableFiles'
import * as scrapingEnvironment from './components/ScrapingEnvironment'
import * as organizations from './components/Organizations'
import * as comments from './components/ClientSupport/CommentModeration'
import * as profiles from './components/Profile/'
import * as projectImports from './components/Projects/Import'
import * as p360Dashboard from './components/Projects/P360'
import * as providerNexus from './components/ProviderNexus'
import * as users from './components/Users'
import * as employers from './components/Employers'
import * as pdm from './components/PDM'
import * as medicareAdvantage from './components/MedicareAdvantage'
import * as network360 from './components/Network360'
import * as statisticsReports from './components/StatisticsReports'
import * as database from './components/InternalQueries'

const requireCollections = requireRole('collections')
const requireProcessing = requireRole('processing')
const requireHarvesting = requireRole('harvesting')
const requireProjects = requireRole('projects')
const requireClientSupport = requireRole('client_support')
const requirePDM = requireRole(['pdm', 'pdm_editor'])
const requirePDMEditor = requireRole('pdm_editor')
const requireUsers = requireRole('users')

export default function Routes() {
  return (
    <AppProvider>
      <Router history={History}>
        <Route
          path="/"
          component={App}
          breadcrumb={<i className="fa fa-home" />}
        >
          <IndexRoute component={Home} useParentBreadcrumb />
          <Route
            path="login"
            component={auth.Login}
            onEnter={redirectIfLoggedIn}
          />
          <Route
            path="forgotPassword"
            component={auth.ForgotPassword}
            onEnter={redirectIfLoggedIn}
          />
          <Route path="confirmations" component={auth.Confirmations} />
          <Route path="reset_password" component={auth.ResetPassword} />
          <Route path="logout" component={auth.Logout} />
          <Route path="loading" component={Loading} useParentBreadcrumb />
          <Route
            path="forbidden"
            component={Forbidden}
            breadcrumb={
              <span>
                <i className="fa fa-meh-o" /> Forbidden
              </span>
            }
          />
          <Route onEnter={requireLogin}>
            <Route
              path="admin"
              component={admin.Admin}
              breadcrumb="Admin"
            />
            <Route path="profile" breadcrumb="Profile">
              <IndexRedirect to="account" />
              <Route
                path="account"
                component={profiles.AccountForm}
                useParentBreadcrumb
              />
              <Route
                path="gravatar"
                component={profiles.Gravatar}
                useParentBreadcrumb
              />
              <Route
                path="password"
                component={profiles.ChangePasswordForm}
                useParentBreadcrumb
              />
            </Route>
            <Route
              path="statisticsreports/productrequests"
              component={statisticsReports.ProductRequests}
              breadcrumb="Product Requests - User Metrics"
            />
            <Route
              path="statisticsreports/efforts"
              component={statisticsReports.Efforts}
              breadcrumb="Data Collection Efforts - User Metrics"
            />
            <Route
              path="projectprocessing"
              component={statisticsReports.ProjectProcessing}
              breadcrumb="Project Processing - User Metrics"
            />
            <Route
              path="internalqueries"
              component={database.InternalQueries}
              breadcrumb="Internal Queries"
            />
            <Route
              path="medicareadvantagereporting"
              component={statisticsReports.MedicareAdvantageReporting}
              breadcrumb="Medicare Advantage Reporting"
            />
            <Route
              path="n360jobmonitor"
              component={projectImports.ImportStatusPage}
              breadcrumb="N360 Job Monitor"
              onEnter={requireProjects}
            />
            <Route
              path="n360jobmonitor/:id"
              component={projectImports.ProjectImportDetail}
              breadcrumb={PatchBreadcrumb}
              onEnter={requireProjects}
            />
            <Route
              path="n360customattributeconfigurations"
              component={network360.CustomAttributesConfigurationsPage}
              breadcrumb="Custom Attribute Configurations"
              onEnter={requireProjects}
            />
            <Route
              path="n360customattributeconfigurations/new"
              component={network360.CreateNewCustomAttributeConfigurationPage}
              breadcrumb="Create New Custom Attribute Configuration"
              onEnter={requireProjects}
            />
            <Route
              path="n360/projects/:projectNumber/attributes/:id"
              component={network360.CustomAttributeConfigurationPage}
              breadcrumb={PatchBreadcrumb}
              onEnter={requireProjects}
            />
            <Route path="p360" onEnter={requireProjects}>
              <IndexRedirect to="dashboard" />
              <Route
                path="dashboard"
                component={p360Dashboard.P360Dashboard}
                breadcrumb="P360 Job Dashboard"
                onEnter={requireProjects}
              />
              <Route
                path="detail/:report_id"
                component={p360Dashboard.P360ReportDetail}
                breadcrumb={PatchBreadcrumb}
                onEnter={requireProjects}
              />
              <Route
                path="new"
                component={p360Dashboard.P360ReportCreate}
                breadcrumb={PatchBreadcrumb}
                onEnter={requireProjects}
              />
            </Route>
            <Route
              path="provider_nexus/requests"
              component={providerNexus.RequestsMonthlySummary}
              breadcrumb="ProviderNexus API Billing"
              onEnter={requireClientSupport}
            />
            <Route
              path="users"
              component={users.UserList}
              breadcrumb="Users"
              onEnter={requireUsers}
            />
            <Route
              path="bulk_add_users"
              component={users.UserBulkAddForm}
              breadcrumb="Bulk Add Users"
            />
            <Route
              path="users/:id"
              component={users.UserDetail}
              breadcrumb={PatchBreadcrumb}
              onEnter={requireUsers}
            >
              <IndexRedirect to="details" />
              <Route
                path="details"
                component={users.UserAddEditForm}
                useParentBreadcrumb
              />
              <Route
                path="features"
                component={users.UserFeatures}
                useParentBreadcrumb
              />
            </Route>
            <Route path="datacollection" onEnter={requireCollections}>
              <IndexRedirect to="efforts" />
              <Route
                path="file_import_management"
                component={fileImport.FileImportDashboard}
                breadcrumb="File Import Management"
              />
              <Route
                path="specialty_aliases"
                component={fileImport.FileImportSpecialtyAliases}
                breadcrumb="Specialty Aliases"
              >
                <Route
                  path="file_import_type/:fileImportTypeId"
                  component={fileImport.FileImportSpecialtyAliases}
                  breadcrumb={PatchBreadcrumb}
                />
              </Route>
              <Route
                path="generation_dashboard"
                component={efforts.GenerationDashboardContainer}
                breadcrumb="Generation Dashboard"
              >
                <IndexRedirect to="manual" />
                <Route
                  path="manual"
                  component={efforts.GenerationDashboardManualList}
                  useParentBreadcrumb
                />
                <Route
                  path="active"
                  component={efforts.GenerationDashboardActiveList}
                  useParentBreadcrumb
                />
                <Route
                  path="completed"
                  component={efforts.GenerationDashboardCompletedList}
                  useParentBreadcrumb
                />
              </Route>
              <Route
                path="efforts"
                component={efforts.DataCollectionEffortList}
                breadcrumb="Manage Data Collection Efforts"
              />
              <Route
                path="efforts/:data_collection_effort_id/generations/:id"
                component={efforts.GenerationDetail}
                breadcrumb={efforts.EffortGenerationBreadcrumb}
              />
              <Route
                path="efforts/:id"
                component={efforts.DataCollectionEffortCreate}
                breadcrumb={efforts.EffortBreadcrumb}
              >
                <IndexRedirect to="summary" />
                <Route
                  path="summary"
                  component={efforts.DataCollectionEffortSummaryForm}
                  useParentBreadcrumb
                />
                <Route
                  path="details"
                  component={efforts.DataCollectionEffortDetailsForm}
                  useParentBreadcrumb
                />
                <Route
                  path="files"
                  component={efforts.DataCollectionEffortFilesList}
                  useParentBreadcrumb
                />
                <Route
                  path="supplier_contacts"
                  component={efforts.SupplierContactList}
                  useParentBreadcrumb
                />
                <Route
                  path="generation_summary"
                  component={efforts.GenerationSummaryContainer}
                  useParentBreadcrumb
                />
                <Route
                  path="documentation"
                  component={efforts.Attachments}
                  useParentBreadcrumb
                />
                <Route
                  path="history"
                  component={efforts.TransactionList}
                  useParentBreadcrumb
                />
              </Route>

              <Route
                path="outreaches"
                component={outreaches.DataCollectionOutreaches}
                breadcrumb="Outreaches"
              />
              <Route
                path="outreaches/:id"
                component={outreaches.OutreachContainer}
                breadcrumb={outreaches.OutreachBreadcrumb}
              >
                <IndexRedirect to="products" />
                <Route
                  path="products"
                  component={outreaches.DataCollectionProducts}
                  useParentBreadcrumb
                />
                <Route
                  path="activities"
                  component={outreaches.DataCollectionOutreachActivities}
                  useParentBreadcrumb
                />
              </Route>

              <Route
                path="requests/:id/changes"
                component={productDetails.ProductChangesPage}
                breadcrumb={PatchBreadcrumb}
              />

              {/*
                  This is a backwards-compatibility route for alerts.

                  /datacollection/products/:id used to take the :id param as
                  data_collection_product_request_id, but with the PRS changes,
                  it takes the client_product_id value now.

                  To fix old alerts that have the request_id in their data, we
                  set up this new route to lookup the client product associated
                  to the request and redirect to the actual route.
                */}
              <Route
                path="requests/:id/:tab"
                component={products.DataCollectionProductRequest}
                breadcrumb="Product Request"
              />

              <Route
                path="products"
                component={products.DataCollectionProductRequests}
                breadcrumb="Product Dashboard"
              />

              <Route
                path="products/:id"
                component={productDetails.ProductDetailPage}
                breadcrumb={PatchBreadcrumb}
              >
                <IndexRedirect to="properties" />
                <Route
                  path="properties"
                  component={productDetails.PropertiesTab}
                  useParentBreadcrumb
                />
                <Route
                  path="carriers"
                  component={productDetails.CarriersTab}
                  useParentBreadcrumb
                />
                <Route
                  path="mapping"
                  component={productDetails.MappingsTab}
                  useParentBreadcrumb
                />
                <Route
                  path="employers"
                  component={productDetails.EmployersTab}
                  useParentBreadcrumb
                />
                <Route
                  path="contacts"
                  component={productDetails.ContactsTab}
                  useParentBreadcrumb
                />
                <Route
                  path="flags"
                  component={productDetails.FlagsTab}
                  useParentBreadcrumb
                />
                <Route
                  path="documents"
                  component={productDetails.DocumentsTab}
                  useParentBreadcrumb
                />
                <Route
                  path="internaldocuments"
                  component={productDetails.InternalDocumentsTab}
                  useParentBreadcrumb
                />
                <Route
                  path="history"
                  component={productDetails.HistoryTab}
                  useParentBreadcrumb
                />

                {/* Redirects from legacy URLs may target non-existant tabs. */}
                <Redirect from="*" to="properties" />
              </Route>
              <Route
                path="qareports/:id"
                component={qaReports.QAReportsPage}
                breadcrumb={PatchBreadcrumb}
              >
                <IndexRedirect to="enrollment" />
                <Route
                  path="enrollment"
                  component={qaReports.EnrollmentTab}
                  useParentBreadcrumb
                />
                <Route
                  path="flags"
                  component={qaReports.FlagsTab}
                  useParentBreadcrumb
                />
                <Route
                  path="tiering"
                  component={qaReports.TieringTab}
                  useParentBreadcrumb
                />
                <Route
                  path="groupmodel"
                  component={qaReports.GroupModelTab}
                  useParentBreadcrumb
                />
              </Route>
            </Route>
            <Route path="dataprocessing" onEnter={requireProcessing}>
              <IndexRedirect to="serviceareas" />
              <Route
                path="serviceareas"
                component={serviceAreas.ServiceAreas}
                breadcrumb="Service Areas"
              />
              <Route
                path="serviceareas/product/:productId/productservicearea/:productServiceAreaId"
                component={serviceAreas.ServiceArea}
                breadcrumb={PatchBreadcrumb}
              >
                <IndexRedirect to="properties" />
                <Route
                  path="properties"
                  component={serviceAreas.ServiceAreaProperties}
                  useParentBreadcrumb
                />
                <Route
                  path="conflictrules"
                  component={serviceAreas.ServiceAreaConflictRules}
                  useParentBreadcrumb
                />
              </Route>
              <Route
                path="networkmappings"
                component={networkMappings.NetworkMappings}
                breadcrumb="Network Mappings"
              />
              <Route
                path="networkmappings/:id"
                component={networkMappings.NetworkMapping}
                breadcrumb={PatchBreadcrumb}
              >
                <IndexRedirect to="properties" />
                <Route
                  path="properties"
                  component={networkMappings.NetworkMappingProperties}
                  useParentBreadcrumb
                />
                <Route
                  path="networks"
                  component={networkMappings.NetworkMappingNetworks}
                  useParentBreadcrumb
                />
                <Route
                  path="networks/version/:version_id"
                  component={networkMappings.NetworkMappingNetworks}
                  useParentBreadcrumb
                />
                <Route
                  path="activeprojects"
                  component={networkMappings.NetworkMappingActiveProjects}
                  useParentBreadcrumb
                />
              </Route>
              <Route
                path="projectmappings"
                component={projectMappings.ProjectMappings}
                breadcrumb="Project Mappings"
              />
              <Route
                path="projectmappings/:id"
                component={projectMappings.ProjectMapping}
                breadcrumb='Project Mappings'
              >
                <IndexRedirect to="properties" />
                <Route
                  path="properties"
                  component={projectMappings.ProjectMappingProperties}
                  breadcrumb={projectMappings.ProjectMappingPropertiesBreadcrumb}
                />
                <Route
                  path="mappings"
                  component={projectMappings.ProjectMappingClientProducts}
                  breadcrumb={projectMappings.ProjectMappingPropertiesBreadcrumb}
                />
                <Route
                  path="generations"
                  component={projectMappings.ProjectMappingGenerations}
                  breadcrumb={projectMappings.ProjectMappingPropertiesBreadcrumb}
                />
                <Route
                  path="activities"
                  component={projectMappings.ProjectMappingGenerationActivities}
                  breadcrumb={projectMappings.ProjectMappingPropertiesBreadcrumb}
                />
                <Route
                  path="apiKeys"
                  component={projectMappings.ProjectMappingApiKeys}
                  breadcrumb={projectMappings.ProjectMappingPropertiesBreadcrumb}
                />
              </Route>
              <Route
                path="projectmappings/:id/generation/:generation_id"
                component={projectMappings.ProjectMappingGeneration}
                breadcrumb={PatchBreadcrumb}
              />
              <Route
                path="projectmappings/:id/generationscompare/:gid1/:gid2"
                component={projectMappings.ProjectMappingGenerationsCompare}
                breadcrumb={PatchBreadcrumb}
              />
              <Route
                path="carrierapprovalrequests"
                component={medicareAdvantage.CarrierApprovalPage}
                breadcrumb="Manage Carrier Approval Requests"
              />
              <Route
                path="carrierapprovalrequests/:id"
                component={medicareAdvantage.CarrierApprovalDetailPage}
                breadcrumb={PatchBreadcrumb}
              />
              <Route
                path="medicareadvantagetemplates"
                component={medicareAdvantage.ProductTemplatesPage}
                breadcrumb="Medicare Advantage Templates"
              />
              <Route
                path="importproducttemplates"
                component={medicareAdvantage.ImportProductTemplatesPage}
                breadcrumb="Import Product Templates"
              />

              {/*
                  There used to be a lot more routes here. They're gone now.
                  Client Products and Product Requests have been merged
                  together in the new Product Dashboard pages, which replace
                  the old UI. Redirects any old links to the new UI.
                */}
              <Redirect
                from="clientproducts/:id*"
                to="/datacollection/products/:id/properties"
              />
            </Route>
            <Route
              path="bulk_add_conflict_rules"
              component={serviceAreas.BulkAddConflictRules}
              breadcrumb="Bulk Add Conflict Rules"
            />
            <Route path="harvesting" onEnter={requireHarvesting}>
              <Route
                path="machinereadablefilemaps"
                component={mrfs.MachineReadableFileMapList}
                breadcrumb="Machine Readable Files"
              />
              <Route
                path="scrapingEnvironment"
                component={scrapingEnvironment.ScrapingEnvironmentPage}
                breadcrumb="Manage Scraping Environment"
              />
              <Route
                path="scrapingprofiledetail/:profileId/site/:siteId"
                component={scrapingEnvironment.ScrapingProfileDetail}
                breadcrumb={scrapingEnvironment.ScrapingProfileDetailBreadcrumb}
              />
              <Route
                path="machinereadablefilemaps/:id"
                component={mrfs.MachineReadableFileMapDetail}
                breadcrumb={mrfs.MachineReadableFileBreadcrumb}
              />
              <Route
                path="machinereadablefilemaps/:id/history"
                component={mrfs.MachineReadableFileMapHistory}
                breadcrumb={mrfs.MachineReadableFileHistoryBreadcrumb}
              />
            </Route>
            <Route
              path="ftpconfigurations/:id"
              component={organizations.FtpConfiguration}
              breadcrumb={organizations.FtpConfigurationBreadcrumb}
            />
            <Route
              path="alerts/:filter"
              component={alerts.AlertsList}
              breadcrumb="Alerts"
            />
            <Route
              path="bookmarks"
              component={alerts.BookmarkList}
              breadcrumb="Bookmarks"
            />
            <Route
              path="sources"
              component={sources.SourceList}
              breadcrumb="Sources"
            />
            <Route
              path="sources/:id"
              component={sources.SourceForm}
              breadcrumb={sources.SourceBreadcrumb}
            >
              <IndexRedirect to="details" />
              <Route
                path="details"
                component={sources.SourceDetail}
                useParentBreadcrumb
              />
              <Route
                path="users"
                component={sources.SourceUsers}
                useParentBreadcrumb
              />
            </Route>
            <Route
              path="organizations"
              component={organizations.OrganizationList}
              breadcrumb="Organizations"
            />
            <Route
              path="organizations/add"
              component={organizations.OrganizationAddForm}
              breadcrumb="Add Organization"
            />
            <Route
              path="organizations/:id"
              component={organizations.OrganizationForm}
              breadcrumb={PatchBreadcrumb}
            >
              <IndexRedirect to="details" />
              <Route
                path="details"
                component={organizations.OrganizationDetail}
                useParentBreadcrumb
              />
              <Route
                path="sources"
                component={organizations.OrganizationSources}
                useParentBreadcrumb
              />
              <Route
                path="features"
                component={organizations.OrganizationFeatures}
                useParentBreadcrumb
              />
              <Route
                path="deliveryconfigurations"
                component={organizations.OrganizationDeliveryConfigurations}
                useParentBreadcrumb
              >
                <IndexRedirect to="ftps" />
                <Route
                  path="ftps"
                  component={organizations.OrganizationFtpConfigurations}
                  useParentBreadcrumb
                />
                <Route
                  path="emails"
                  component={organizations.OrganizationEmailConfigurations}
                  useParentBreadcrumb
                />
                <Route
                  path="websites"
                  component={organizations.OrganizationWebsiteConfigurations}
                  useParentBreadcrumb
                />
              </Route>
              <Route
                path="users"
                component={organizations.OrganizationUsers}
                useParentBreadcrumb
              />
              <Route
                path="contacts"
                component={organizations.OrganizationContacts}
                useParentBreadcrumb
              />
              <Route
                path="employers"
                component={organizations.OrganizationEmployers}
                useParentBreadcrumb
              />
            </Route>
            <Route
              path="organizations/employers/:id"
              component={organizations.OrganizationEmployer}
              breadcrumb={organizations.OrganizationEmployerBreadcrumb}
            />
            <Route
              path="employers"
              component={employers.Employers}
              breadcrumb="Employers"
            />
            <Route
              path="moderatedComments"
              component={comments.ModeratedComments}
              breadcrumb="Moderated Comments"
              onEnter={requireClientSupport}
            >
              <IndexRedirect to="new" />
              <Route
                path="new"
                component={comments.NewComments}
                useParentBreadcrumb
              />
              <Route
                path="completed"
                component={comments.CompletedComments}
                useParentBreadcrumb
              />
            </Route>
            <Route path="pdm">
              <Route path="contracts" onLeave={deleteTaxId}>
                <Route
                  path="search"
                  component={pdm.SearchPage}
                  breadcrumb="Search"
                  onEnter={requirePDM}
                />
                <Route
                  path="new"
                  component={pdm.AddEditContract}
                  breadcrumb="Add New Contract"
                  onEnter={requirePDMEditor}
                />
                <Route
                  path=":id/edit"
                  component={pdm.AddEditContract}
                  breadcrumb="Edit Contract"
                  onEnter={requirePDMEditor}
                />
                <Route
                  path=":id"
                  component={pdm.ContractPage}
                  breadcrumb="Contract Details"
                  onEnter={requirePDM}
                />
              </Route>
              <Route
                path="affiliates"
                component={pdm.ManageAffiliates}
                breadcrumb="Affiliates"
                onEnter={requirePDMEditor}
              />
              <Route
                path="affiliates/:id"
                component={pdm.AffiliateDetail}
                breadcrumb={PatchBreadcrumb}
                onEnter={requirePDMEditor}
              >
                <IndexRedirect to="properties" />
                <Route
                  path="properties"
                  component={pdm.AffiliateProperties}
                  useParentBreadcrumb
                />
                <Route
                  path="networks"
                  component={pdm.AffiliateNetworks}
                  useParentBreadcrumb
                />
                <Route
                  path="editrules"
                  component={() => null}
                  useParentBreadcrumb
                />
                <Route
                  path="documents"
                  component={pdm.AffiliateDocuments}
                  useParentBreadcrumb
                />
              </Route>
              <Route
                path="auditreports"
                component={audits.AuditReport}
                breadcrumb="Audit Reports"
              />
              <Route
                path="auditreports/:affiliate_id/:stat_type"
                component={audits.AuditReportDetails}
                breadcrumb={PatchBreadcrumb}
              />
              <Route
                path="feeschedules"
                component={pdm.ManageFeeSchedules}
                breadcrumb="Fee Schedules"
                onEnter={requirePDMEditor}
              />
              <Route
                path="feeschedules/:id"
                component={pdm.FeeSchedulePage}
                breadcrumb={PatchBreadcrumb}
                onEnter={requirePDMEditor}
              >
                <IndexRedirect to="properties" />
                <Route
                  path="properties"
                  component={pdm.FeeScheduleProperties}
                  useParentBreadcrumb
                />
                <Route
                  path="details"
                  component={pdm.FeeScheduleDetails}
                  useParentBreadcrumb
                />
              </Route>
              <Route
                path="networks"
                component={pdm.ManageNetworks}
                breadcrumb="Networks"
                onEnter={requirePDMEditor}
              />
              <Route
                path="networks/:id"
                component={pdm.NetworkPage}
                breadcrumb={PatchBreadcrumb}
                onEnter={requirePDMEditor}
              />
              <Route
                path="networkaffiliates/:id"
                component={pdm.NetworkAffiliatePage}
                breadcrumb={PatchBreadcrumb}
                onEnter={requirePDMEditor}
              />
              <Route
                path="networkaffiliates/:network_affiliate_id/raterules/:id"
                component={pdm.RateRulePage}
                breadcrumb={PatchBreadcrumb}
                onEnter={requirePDMEditor}
              />
              <Route
                path="exclusions"
                component={pdm.ManageExclusions}
                breadcrumb="Exclusions"
              />
            </Route>
          </Route>
          <Route path="error" component={ErrorPage} breadcrumb="Error" />
          <Route
            path="*"
            component={RouteNotFound}
            breadcrumb="Page not found"
          />
        </Route>
      </Router>
    </AppProvider>
  )
}
